import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "Learn to Draw",
  "date": "2018-05-01\"",
  "promo": "flexbox",
  "description": "Solve problems better by learning to draw."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Programming can be fun and rewarding work, especially when what we're creating challenges us. But any difficult undertaking inevitably means hitting roadblocks. Getting stumped. Having no idea how to overcome the latest problem or how to proceed. Coding is about solving problems, one after another. One of the most valuable tools you can add to your problem-solving aresenal is the ability to draw.`}</p>
    <h2>{`Drawing is Seeing`}</h2>
    <p>{`What does drawing have to do with solving problems? I'm taking a drawing course from renowned illustrator `}<a parentName="p" {...{
        "href": "https://www.thomasfluharty.com/artist/"
      }}>{`Thomas Fluharty`}</a>{` where he asks students this simple question: `}<em parentName="p">{`what is drawing?`}</em></p>
    <p>{`To which he answers:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Drawing is seeing. When you're seeing, you're observing. And when you're observing, you're asking questions. The right questions.`}</p>
    </blockquote>
    <p>{`The process of drawing is the process of asking yourself questions. Usually when we get stuck on a problem we ask ourselves: `}<em parentName="p">{`what do I know?`}</em>{` But in drawing, you throw out what you know and ask yourself the more helpful question: `}</p>
    <p><strong parentName="p">{`what do I see?`}</strong></p>
    <p>{`Learning to draw helps us see past our assumptions. Explanations our brains would have classified as "not possible" become on the table. `}</p>
    <p>{`One time I was working on adding a new card size to my project at work. Because I'm `}<em parentName="p">{`amazing`}</em>{` at estimates, I thought it would take only a few minutes. I found where the other card sizes appeared to be defined, and added mine. I closed the ticket and went to lunch. I came back to the app completely busted in the dev environment. After a `}<inlineCode parentName="p">{`git revert`}</inlineCode>{` and bit of shame, I sat down to figure out what went wrong.`}</p>
    <p>{`As it turned out, the card sizing feature was a fragile and complex beast summoned from the depths of hell. I tackled it the only way I knew how. I grabbed an 11x17 sheet of paper from the office supply closet and started digging through the code. As I discovered a useful clue, I'd sketch it out on my paper. A couple of hours later I had a diagram drawn that accurately illustrated how the feature worked. Once I understood it, changing it was a snap. `}</p>
    <p>{`Another time I was working on lining up the user-controlled terrain items in `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{`. My boxes were slightly off and I didn't know why. So I did what has become a favorite technique of mine: I snapped a picture of my Mac's screen with my iPad's camera, and opened up the photo in a drawing app on the iPad. `}</p>
    <p><img parentName="p" {...{
        "src": "/img/drawing-problem.jpg",
        "alt": "drawing the problem"
      }}></img></p>
    <p>{`That let me draw right on top of my UI. I asked myself what behavior I was `}<em parentName="p">{`seeing`}</em>{`, and what I `}<em parentName="p">{`wanted`}</em>{` it to be doing. With that clarity I was able to think through what was not quite right and quickly implement a solution.`}</p>
    <p>{`You can't solve a problem you can't see clearly. In my experience the best way to see something clearly is to draw it out. `}</p>
    <p>{`Drawing helps you see not only `}<em parentName="p">{`current`}</em>{` problems but `}<em parentName="p">{`future`}</em>{` ones as well.`}</p>
    <h2>{`Drawing is Prototyping`}</h2>
    <p>{`Prototyping is nothing more than getting an idea out of your head so that it can answer a specific question. Drawing is the fastest form of prototyping and can answer most questions.`}</p>
    <p>{`In my game I wanted little interceptor ships to fly out of the space ship and arrive at specific locations, based on the user's CSS Grid template & gaps. Before typing a single character of code, I drew this out:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/drawing-interceptors.jpg",
        "alt": "drawing as prototyping"
      }}></img></p>
    <p>{`Drawing this helped me think through how I would build it: each ship would have three coordinates they would travel to: `}</p>
    <ol>
      <li parentName="ol">{`Right next to the ship, based on the direction it's facing.`}</li>
      <li parentName="ol">{`A small distance away from the final destination.`}</li>
      <li parentName="ol">{`Final destination at the edge of the grid track.`}</li>
    </ol>
    <p>{`Then as I wrote and stepped through the code, I could refer often to a specific interceptor in my drawing and quickly see whether I was building it correctly.`}</p>
    <p>{`Even though this made me realize I'd forgetten pretty much everything from college trig class, I'm pretty happy with how it turned out. Here's the result in slow motion so you can see how the interceptors follow the paths:`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/building-from-drawing.gif",
        "alt": "building from the drawing"
      }}></img></p>
    <p>{`This simple drawing didn't take long at all to think through and create. It answered my question, then it guided what I ultimately coded. I didn't need mocks or a requirements doc. Just the bit of seeing and thinking that comes whenever you draw.`}</p>
    <p>{`Here's another example of a prototype drawing: the chat panel UI I show how to build in `}<a parentName="p" {...{
        "href": "/post/dynamic-svg-components/"
      }}>{`Dynamic SVG Components`}</a>{`.`}<br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "/img/drawing-dynamic-svg.jpg",
        "alt": "drawing dynamic svg"
      }}></img></p>
    <h2>{`Start Drawing`}</h2>
    <p>{`Start leveraging the power of drawing to solve problems. Don't fret if you don't currently consider yourself a "visual thinker" - that's something that will come as you train yourself to think visually by drawing.`}</p>
    <p>{`Getting good at drawing will really pay off in the long run, definitely something to add to your regular `}<a parentName="p" {...{
        "href": "/post/routine-success/"
      }}>{`routine`}</a>{`. Here's what I recommend for getting started:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go through the lessons on `}<a parentName="p" {...{
            "href": "https://drawabox.com/"
          }}>{`drawabox.com`}</a>{`. They're completely free and start with the very basics. I'm going through these right now myself and have already seen an improvement in my skills.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You don't `}<em parentName="p">{`need`}</em>{` any digital tools for this but I've found the iPad/Apple Pencil to be compeletely awesome for this. I prefer this setup over something like a Wacom tablet, because you can take it with you on a walk or to the park `}<a parentName="p" {...{
            "href": "/post/unstuck"
          }}>{`when you get stuck`}</a>{`. The best iOS app I've found so far is `}<a parentName="p" {...{
            "href": "https://concepts.tophatch.com/"
          }}>{`Concepts`}</a>{` because of its infinite canvas. I also like `}<a parentName="p" {...{
            "href": "https://procreate.art/"
          }}>{`Procreate`}</a>{` for freestyle drawing/painting.`}</p>
      </li>
    </ol>
    <p>{`The next time you find yourself solving a problem while coding - try drawing it!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      